<template>
  <div class="reset">
    <h1>Восстановление пароля</h1>
    <input type="text" v-model="reset.password" placeholder="Введите пароль">
    <input type="text" v-model="reset.replace_password" placeholder="Введите пароль повторно">
    <button class="btn" @click="changePassword">Сменить пароль</button>
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  name: 'ResetMain',
  data() {
    return {
      reset: {
        password: '',
        replace_password: '',
        token: this.getQueryParam('token'),
      }
    }
  },
  mounted() {
  
  },
  methods: {
    changePassword() {
      Axios
        .post(`/api/auth/reset`, this.reset)
        .then(res => {
          this.$router.push('/auth');
        })
        .catch(err => {
          this.$bus.$emit('showNotification', {message: err.response.data, status: 'danger'});
          console.error(err.response);
        })
    },
    getQueryParam (param) {
      let queries = window.location.search, regex, resRegex, results, response;
      param = encodeURIComponent(param);
      regex = new RegExp('[\\?&]' + param + '=([^&#]*)', 'g');
      resRegex = new RegExp('(.*)=([^&#]*)');
      results = queries.match(regex);

      if (!results) {
          return '';
      }
      response = results.map(function (result) {
        let parsed = resRegex.exec(result);

        if (!parsed) {
            return '';
        }

        return parsed[2].match(/(^\d+$)/) ? Number(parsed[2]) : parsed[2];
      })

      return response.length > 1 ? response : response[0];
    },
  }
}
</script>

<style lang="scss" scoped>
.reset {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 250px;
  h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  input {
    margin-bottom: 10px;
  }
  .btn {
    height: 40px !important;
    font-size: 14px !important;
  }
}

</style>