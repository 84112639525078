<template>
  <ResetMain />
</template>

<script>
import axios from "axios";
import ResetMain from "@/components/game/auth/ResetMain";

export default {
  name: "Reset",
  components: {
    ResetMain,
  },
};
</script>

<style>
</style>